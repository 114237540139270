@font-face {
  font-family: "Northura-bold";
  src: local("Northura-bold"),
    url("../fonts/Northura-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Northura-black";
  src: local("Northura-black"),
    url("../fonts/Northura-Black.otf") format("opentype");
}
@font-face {
  font-family: "Northura-light";
  src: local("Northura-light"),
    url("../fonts/Northura-Light.otf") format("opentype");
}
@font-face {
  font-family: "Northura-medium";
  src: local("Northura-medium"),
    url("../fonts/Northura-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Northura-regular";
  src: local("Northura-regular"),
    url("../fonts/Northura-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Northura-thin";
  src: local("Northura-thin"),
    url("../fonts/Northura-Thin.otf") format("opentype");
}
