@import "./fonts.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --radial-gradient-background: transparent;
  --solid-color-background: 15, 15, 15;
  --overlay-color: 255, 255, 255;
}

.custom-line-clamp-8 {
  display: -webkit-box;
  -webkit-line-clamp: 9; /* Adjust this number to control the number of lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
